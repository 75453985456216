import "./header.scss";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classNames from "classnames";
import get from "lodash/get";
import MainHeader from "app/pages/Header/Header";

const Header = props => {
	const { shop, fallbackRedirectUrl } = props;

	const language = shop.slice(0, 2);

	// div > header + nav au lieu de header > nav pour que nav passe en dessous

	const subMenuLinks = [
		{
			id: "submenu.home",
			link: `https://www.my-store.ch/${language}/`,
		},
		{
			id: "submenu.your.town",
			link: `https://www.my-store.ch/${language}/city/`,
		},
		{
			id: "submenu.travelandflights",
			link: fallbackRedirectUrl,
		},
		{
			id: "submenu.fashion",
			link: `https://www.my-store.ch/${language}/fashion/`,
		},
		{
			id: "submenu.cosmetics",
			link: `https://www.my-store.ch/${language}/cosmetics/`,
		},
		{
			id: "submenu.living",
			link: `https://www.my-store.ch/${language}/home-living/`,
		},
		{
			id: "submenu.sport",
			link: `https://www.my-store.ch/${language}/sport/`,
		},
		{
			id: "submenu.wine.gourmet",
			link: `https://www.my-store.ch/${language}/gourmet/`,
		},
	];

	return (
		<>
			<MainHeader {...props} />
			<div className="header__sticky-container">
				<section className="header__submenu">
					<div className="header__submenu__scroll-menu">
						{subMenuLinks.map(item => (
							<a
								className={classNames("header__submenu__scroll-menu__item", {
									"header__submenu__scroll-menu__item--selected":
										item.id === "submenu.travelandflights",
								})}
								key={item.id}
								href={item.link}
							>
								<FormattedMessage id={item.id} />
							</a>
						))}
					</div>
				</section>
			</div>
		</>
	);
};

Header.propTypes = {
	dialPhone: PropTypes.string,
	merchandisingCode: PropTypes.string,
	shop: PropTypes.string,
	fallbackRedirectUrl: PropTypes.string,
	useBrowserBackOnProductPage: PropTypes.bool,
};

const mapStateToProps = state => {
	return {
		merchandisingCode: get(state, "merchandising.current.code"),
		shop: state.shop,
	};
};

export default connect(mapStateToProps)(Header);
